@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

body {
  margin: 0;
  font-family: 'Inter', serif;
  background-color: #FEF7F9;
}

h2 {
  margin: 0;
  font-family: 'Poppins', serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-img{
  background-image: url(./assets/img/hero.png);
}

.bg-features{
  background-image: url(./assets/img/FeaturesHero.png);
}

.bg-contact{
  background-image: url(./assets/img/contactImg.png);
}

.bg-faq{
  background-image: url(./assets/img/bg-faq.png);
}

.bg-jigsaw{
  background-image: url(./assets/img/jigsaw.png);
}
